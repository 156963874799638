import React, { useState, useRef } from "react";
import { Box, Typography, Link, Stack, useTheme } from "@mui/material";
import { greenLinkStyle, loginRedirectLink } from "../helpers/styling";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  NavLink,
  useHref,
} from "react-router-dom";
export default function InfoBox(props) {
  var { sx, titleText, bodyText, jumpTo } = props;
  var theme = useTheme();
  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        pt: 3,
        pl: 3,
        pr: 3,
        pb: 2,
        height: "100%",
        display: "flex",
      }}
    >
      <NavLink to={jumpTo} style={{ textDecoration: "none" }}>
        <Stack
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            direction: "column",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            {titleText}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: 18,
              flex: 1,
              pt: 1,
            }}
          >
            {bodyText}
          </Typography>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Typography style={greenLinkStyle}>
              Learn more{" "}
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ transform: "scale(0.8)", verticalAlign: "middle" }}
              />
            </Typography>
          </Box>
        </Stack>
      </NavLink>
    </Box>
  );
}
