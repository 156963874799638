import logo from "./logo.svg";
import "./App.css";
import ReactDOM from "react-dom/client";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import RegisterDialog from "./components/RegisterDialog";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  NavLink,
  useHref,
  useLocation,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Home from "./pages/Home";
import About from "./pages/About";
import Roasters from "./pages/Roasters";
import BuyCoffee from "./pages/BuyCoffee";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Blog from "./pages/Blog/Blog";

import GetUpdates from "./components/GetUpdates";

import Footer from "./components/Footer";

import { ReactComponent as Logo } from "./imgs/logo3.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { PostHogProvider } from "posthog-js/react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Container,
  AppBar,
  Stack,
  Box,
  Toolbar,
  SvgIcon,
  Button,
  Link,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

import {
  actionButton,
  linkStyle,
  loginRedirectLink,
  menuLinkStyle,
  menuRegisterButton,
  greenLinkStyle,
  menuActionButton,
  simpleLinkStyle,
} from "./helpers/styling";
import { ElevenMp } from "@mui/icons-material";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import useMediaQuery from "@mui/material/useMediaQuery";
import Register from "./components/Register";
import TermsOfService from "./pages/TermsOfService";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOpoRBowpOtS1zHXtAJ7Q_hx8aDI1B-HU",
  authDomain: "haste-roastery-website.firebaseapp.com",
  projectId: "haste-roastery-website",
  storageBucket: "haste-roastery-website.appspot.com",
  messagingSenderId: "181666686612",
  appId: "1:181666686612:web:ca39c7cd3d6da5a24e7681",
  measurementId: "G-84K9953X89",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const posthog_options = {
  api_host: "https://app.posthog.com",
};

var mainColor = "#5F27CD";
var secondaryColor = "#1DD1A1";
var tertiaryColor = "#0984E3";
var mainText = "#243B53";

const theme = createTheme({
  typography: {
    allVariants: {
      color: mainText,
    },
    h1: {
      color: mainColor,
      fontWeight: 600,
      fontSize: 42,
    },
    h2: {
      color: secondaryColor,
      fontWeight: 600,
      fontSize: 36,
    },
    h3: {
      color: "white",
      fontWeight: 600,
    },
    h4: {
      color: mainColor,
      fontWeight: 300,
    },
    h5: {
      color: mainColor,
      fontWeight: 600,
    },
    subtitle1: { fontSize: 22 },
    subtitle2: {
      fontSize: 20,
      fontWeight: 300,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      color: "white",
    },
  },
  palette: {
    primary: {
      main: mainColor,
      secondary: secondaryColor,
      tertiary: tertiaryColor,
      quaternary: "#FF9F43",
      text: mainText,
    },
    darker: {
      main: "#341F97",
      secondary: "#1DA380",
    },
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ElevationScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  return React.cloneElement(children, {
    // elevation: trigger ? 4 : 0,
    /*
    style: {
      backgroundColor: trigger ? "#341F97" : "transparent",
      transition: trigger ? "0.5s" : "0.3s",
      height: trigger ? "60px" : "100px",
    },*/
    style: {
      backgroundColor: trigger ? "#341F97" : "#341F97",
      height: trigger ? "70px" : "70px",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function App() {
  const matches = useMediaQuery("(min-width:600px)");
  const [menuOpen, setMenuOpen] = useState(false);

  const [registerOpen, setRegisterOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function toggleRegister() {
    setRegisterOpen(!registerOpen);
  }
  /*
  <Link href="https://hasteroastery.io/" style={loginRedirectLink}>
    Register
  </Link>;
*/
  return (
    <PostHogProvider
      apiKey={"phc_MHStoVwWKDFGO7rtp0RBtCIwNW4RHDU9KCtpKv2HIbT"}
      options={posthog_options}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Box className="App">
            <Stack>
              <ElevationScroll>
                <AppBar position="fixed" style={{}} elevation={0} sx={{}}>
                  <Container disableGutters sx={{ height: "100%" }}>
                    <Toolbar
                      disableGutters
                      sx={{
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Box
                          sx={{
                            height: "50px",
                            width: "100px",
                            display: "flex",
                            alignItems: "center",
                            p: 2,
                          }}
                        >
                          <Logo />
                        </Box>
                      </NavLink>
                      {matches ? (
                        <React.Fragment>
                          <Box>
                            <Stack direction="row" spacing={3}>
                              <NavLink
                                to="/roasters"
                                style={linkStyle}
                                className={({ isActive }) =>
                                  isActive ? "is-active-nav" : "inactive"
                                }
                              >
                                Roasters
                              </NavLink>
                              <Box sx={{ display: "none" }}>
                                <NavLink
                                  to="/buy-coffee"
                                  style={linkStyle}
                                  className={({ isActive }) =>
                                    isActive ? "is-active-nav" : "inactive"
                                  }
                                >
                                  Buy Coffee
                                </NavLink>
                              </Box>

                              <NavLink
                                to="/about"
                                style={linkStyle}
                                className={({ isActive }) =>
                                  isActive ? "is-active-nav" : "inactive"
                                }
                              >
                                About
                              </NavLink>
                            </Stack>
                          </Box>
                          <Box>
                            <Stack direction="row" spacing={3}>
                              <Button
                                onClick={() =>
                                  (window.location.href =
                                    "https://hasteroastery.io/")
                                }
                                style={loginRedirectLink}
                                sx={{}}
                              >
                                Log In / Register
                              </Button>
                              <Button
                                onClick={toggleRegister}
                                style={loginRedirectLink}
                                sx={{ display: "none" }}
                              >
                                Sign Up
                              </Button>
                            </Stack>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <Box sx={{ p: 2 }}>
                          <IconButton onClick={toggleMenu}>
                            <MenuIcon
                              fontSize="large"
                              sx={{ color: "white" }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Toolbar>
                  </Container>
                </AppBar>
              </ElevationScroll>
              <Box sx={{ minHeight: "100vh", backgroundColor: "#F0F4F8" }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <React.Fragment>
                        <Home />
                        <Roasters />
                        <Box
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            p: matches ? 10 : 2,
                          }}
                        >
                          <Grid
                            container
                            spacing={{ xs: 5 }}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ textAlign: matches ? "left" : "center" }}
                          >
                            <Grid item xs={12} md={6} sx={{ pl: 5 }}>
                              <Typography
                                variant="h3"
                                sx={{ fontSize: 38, color: "white" }}
                              >
                                Stay in the know
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: 24, color: "white" }}
                              >
                                Get notified on news and updates
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <GetUpdates />
                            </Grid>
                          </Grid>
                        </Box>
                      </React.Fragment>
                    }
                  />
                  <Route path="/about" element={<About />} />
                  <Route path="/roasters" element={<Roasters />} />
                  <Route path="/buy-coffee" element={<BuyCoffee />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  {/*
                  <Route path="/blog">
                    <Route path=":blogid" element={<Blog />} />
                    <Route path="" element={<Blog />} />
                  </Route>
                  <Route path="/blog" element={<Blog />} />
                      */}
                  <Route path="/*" element={<Home />} />
                  {/*   
              <Route path="/*" element={<BuyCoffee />} /> */}
                </Routes>
              </Box>
              <Footer />
            </Stack>
          </Box>
          <Dialog
            fullScreen={true}
            open={menuOpen}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent sx={{ backgroundColor: theme.palette.primary.main }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <IconButton onClick={toggleMenu} sx={{}}>
                    <CloseIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 10 }}>
                  <Stack direction="column" spacing={5}>
                    <NavLink to="/" style={menuLinkStyle} onClick={toggleMenu}>
                      Home
                    </NavLink>
                    <NavLink
                      to="/roasters"
                      style={menuLinkStyle}
                      onClick={toggleMenu}
                    >
                      Roasters
                    </NavLink>
                    <Box sx={{ display: "none" }}>
                      <NavLink
                        to="/buy-coffee"
                        style={menuLinkStyle}
                        onClick={toggleMenu}
                      >
                        Buy Coffee
                      </NavLink>
                    </Box>

                    <NavLink
                      to="/about"
                      style={menuLinkStyle}
                      onClick={toggleMenu}
                    >
                      About
                    </NavLink>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    mt: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={simpleLinkStyle}
                    sx={{
                      alignItems: "center",
                      backgroundColor: "rgb(52, 31, 151)",
                      pt: 3,
                      pb: 3,
                      pl: 5,
                      pr: 5,
                      borderRadius: 15,
                      fontSize: 16,
                    }}
                  >
                    Visit on Desktop to sign in
                  </Typography>
                  {/*
                  <Button
                    onClick={toggleRegister}
                    style={menuActionButton}
                    sx={{}}
                  >
                    Sign Up
                  </Button>
*/}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          <RegisterDialog open={registerOpen} onClose={toggleRegister} />
        </Router>
      </ThemeProvider>
    </PostHogProvider>
  );
}

export default App;
