import React from "react";
import { Box } from "@mui/material";
import GetUpdates from "../components/GetUpdates";

export default function About() {
  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#3F25B3",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GetUpdates />
      </Box>
    </React.Fragment>
  );
}
