import React from "react";
import {
  Box,
  Stack,
  Typography,
  Container,
  Grid,
  Fade,
  Slide,
  Zoom,
} from "@mui/material";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Register from "../components/Register";
import GetUpdates from "../components/GetUpdates";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  NavLink,
  useHref,
} from "react-router-dom";
import { ReactComponent as Wave } from "../imgs/wave.svg";
import { actionButton, heroBg, simpleLinkStyle } from "../helpers/styling";
import { useTheme } from "@mui/material/styles";
import InfoBox from "../components/InfoBox";
import useMediaQuery from "@mui/material/useMediaQuery";

function ElevationScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1200,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      transition: trigger ? "0.8s" : "0.6s",
      borderRadius: trigger ? "0px" : "20px",
      transform: trigger
        ? "translate3d(0px, 0%, 0px) scale3d(1, 1, 1)"
        : "translate3d(0px, 0%, 0px) scale3d(0.85, 0.85, 1)",
      //   marginLeft: trigger ? 0 : "4vw",
      //   marginRight: trigger ? 0 : "4vw",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Home() {
  var theme = useTheme();
  const matches = useMediaQuery("(min-width:1100px)");

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#341F97",
          height: "700px",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid item sm={12} md={6} sx={{ height: "100%" }}>
            {" "}
            <Container
              sx={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: matches ? "flex-end" : "center",
                  justifyContent: matches ? "flex-end" : "center",
                  textAlign: matches ? "" : "center",
                }}
              >
                <Stack sx={{ pb: matches ? 18 : 0 }}>
                  <Typography
                    sx={{ color: "white", fontSize: 46, fontWeight: 600 }}
                  >
                    Get to know your coffee
                  </Typography>
                  <Typography
                    sx={{ color: "white", fontSize: 30, fontWeight: 300 }}
                  >
                    Manage your roastery with Haste
                  </Typography>
                  <Box
                    sx={{
                      mt: 3,
                    }}
                  >
                    <NavLink to="/roasters" style={actionButton}>
                      Roasters{" "}
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{ verticalAlign: "middle", ml: 0.5 }}
                      />
                    </NavLink>
                  </Box>
                </Stack>
              </Box>
            </Container>
          </Grid>
          {matches ? (
            <Grid item sm={12} md={6}>
              <Box
                sx={{
                  position: "absolute",
                  width: "800px",
                  height: "800px",
                  top: 100,
                  right: 0,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    transformOrigin: "right top",
                    transform: "scale(0.7)",
                  }}
                >
                  <Fade
                    in={true}
                    timeout={{ enter: 1000 }}
                    style={{ transitionDelay: "250ms" }}
                  >
                    <Box
                      sx={{
                        boxShadow: "0 0px 0px 12px rgba(255,255,255,0.4)",
                        height: "900px",
                        width: "850px",
                        borderRadius: "15px",
                        border: "4px solid white",
                        transform: "translate(-50px, 100px)",
                      }}
                    >
                      <img
                        src="./main-slim.png"
                        loading="lazy"
                        style={{
                          boxShadow: 2,
                          borderRadius: "10px",
                          height: "100%",
                        }}
                      />
                    </Box>
                  </Fade>
                  <Fade in={true} timeout={{ enter: 1000 }}>
                    <Box>
                      <Slide direction="up" in={true} timeout={{ enter: 750 }}>
                        <Box
                          sx={{
                            boxShadow: "0 0px 0px 12px rgba(255,255,255,0.4)",
                            height: "530px",
                            width: "300px",
                            borderRadius: "15px",
                            border: "3px solid white",
                            position: "absolute",
                            bottom: -150,
                            right: 625,
                          }}
                        >
                          <img
                            src="./consumer1.jpeg"
                            loading="lazy"
                            style={{
                              boxShadow: 2,
                              borderRadius: "15px",
                              width: "100%",
                            }}
                          />
                        </Box>
                      </Slide>
                    </Box>
                  </Fade>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{ p: 3, position: "absolute", top: 580 }}
            >
              <Box
                sx={{
                  borderRadius: "15px",
                }}
              >
                <img
                  src="./main-slim.png"
                  loading="lazy"
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 100,
                  right: 25,
                }}
              >
                <Box
                  sx={{
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src="./consumer1.jpeg"
                    loading="lazy"
                    style={{
                      width: "30%",
                      borderRadius: "15px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Stack sx={{ mt: 30 }} spacing={{ xs: 25, md: 10 }}>
        <Box>
          <Stack
            direction="column"
            sx={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Stack
                spacing={8}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  spacing={2}
                >
                  <Typography variant="h4">
                    Love a perfect cup of coffee?
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 28 }}>
                    Us too
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
                    Coffee roasting is an art. So get the data you need to
                    elevate your roasts and engage directly with your community
                    through Haste Roastery management software.
                  </Typography>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 4 }}
                  sx={{}}
                >
                  <InfoBox
                    sx={{
                      width: matches ? "375px" : "300px",
                      minHeight: "225px",
                    }}
                    titleText="Roasters"
                    bodyText="
                    Find new greens, plan your roasts, and leverage longitudinal roast tracking data to push your roastery to the next level."
                    jumpTo="/roasters"
                  />{" "}
                  <InfoBox
                    sx={{
                      width: matches ? "375px" : "300px",
                      minHeight: "225px",
                    }}
                    titleText="Community Engagement"
                    bodyText="
                    Deliver detailed information directly to your consumers. Provide them with optimal brewing tips, freshness dates, and get feedback on your roasts."
                    jumpTo="/roasters"
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box>
          <ElevationScroll>
            <Box
              sx={{
                backgroundColor: theme.palette.darker.secondary,
              }}
            >
              <Grid container style={heroBg}>
                <Grid
                  item
                  xs={12}
                  md={6.5}
                  sx={{
                    p: matches ? 12 : 5,
                  }}
                >
                  <Stack
                    spacing={6}
                    sx={{ height: "100%", justifyContent: "center" }}
                  >
                    <Typography variant="h3">
                      Spend more time perfecting your craft
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "white" }}>
                      Whether you are a hobby or micro roaster, or a large
                      roastery, we’ve got you covered.
                      <br />
                      <br />
                      No matter if you are collecting real time data from your
                      roaster with Artisan, or manually tracking your roast data
                      by hand - get a birds eye view into past roast
                      performance, queue up your next batch, and analyze
                      longitudinal data across all roasts. Organize your roasts
                      to make the data actionable.
                    </Typography>
                    <NavLink to="/roasters" style={simpleLinkStyle}>
                      Learn more about Haste
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{
                          verticalAlign: "middle",
                          transform: "scale(0.8)",
                        }}
                      />
                    </NavLink>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ p: 3 }}>
                  <Box sx={{}}>
                    <img
                      src="./roaster3.jpg"
                      loading="lazy"
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ElevationScroll>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
