import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Stack, TextField, Button } from "@mui/material";
import {
  linkStyle,
  loginRedirectLink,
  actionButton,
  subscribeButton,
  successButton,
} from "../helpers/styling";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";

const firebaseConfig = {
  apiKey: "AIzaSyAOpoRBowpOtS1zHXtAJ7Q_hx8aDI1B-HU",
  authDomain: "haste-roastery-website.firebaseapp.com",
  projectId: "haste-roastery-website",
  storageBucket: "haste-roastery-website.appspot.com",
  messagingSenderId: "181666686612",
  appId: "1:181666686612:web:ca39c7cd3d6da5a24e7681",
  measurementId: "G-84K9953X89",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const SubscribeBox = styled(TextField)(() => ({
  "& input": {
    paddingLeft: "30px",
    height: "16px",
    color: "#341f97",
    backgroundColor: "white",
    borderRadius: "30px 0 0 30px",
    maxWidth: "250px",
  },
  "& fieldset": {
    borderRadius: "30px 0 0 30px",
    border: "none",
  },
}));

export default function GetUpdates(props) {
  var [subFieldVal, setSubFieldVal] = useState("");
  var [success, setSuccess] = useState(false);
  var [loading, setLoading] = useState(false);

  const handleSubFieldChange = (event) => {
    setSubFieldVal(event.target.value);
    setSuccess(false);
  };

  async function addSubscriber() {
    const email = subFieldVal;
    console.log(email);
    var currentEmails = email.split(",").filter((e) => e && e.trim());
    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    for (var i = 0; i < currentEmails.length; i++) {
      if (!regex.test(currentEmails[i].replace(/\s/g, ""))) {
        console.log("Email failed validation");
        return false;
      }
    }
    if (email == "") return;
    // ...
    const subRef = doc(db, "EmailSubscribers", "Subscribers");

    // Atomically add a new region to the "regions" array field.
    setLoading(true);
    await updateDoc(subRef, {
      emails: arrayUnion(email),
      timestamp: serverTimestamp(),
    }).then(() => {
      setSuccess(true);
      setLoading(false);
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row">
          <SubscribeBox
            variant="outlined"
            placeholder="you@example.com"
            onChange={handleSubFieldChange}
            value={subFieldVal}
          ></SubscribeBox>
          <Button
            style={success ? successButton : subscribeButton}
            onClick={addSubscriber}
          >
            {success && (
              <CheckIcon
                fontSize="small"
                style={{ color: "white" }}
                sx={{ mr: 1 }}
              />
            )}
            {loading && <CircularProgress size={20} sx={{ mr: 1 }} />}
            {success ? "Success" : "Subscribe"}
          </Button>
        </Stack>

        <Typography sx={{ fontWeight: 300, fontSize: 12, color: "white" }}>
          By signing up for updates, you agree to our{" "}
          <Link to="/privacy-policy" style={{ fontSize: 12, color: "white" }}>
            Privacy Policy
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
