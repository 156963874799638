export const simpleLinkStyle = {
  textDecoration: "none",
  color: "white",
  fontWeight: 700,
};

export const smallLinkStyle = {
  ...simpleLinkStyle,
  fontWeight: 500,
  fontSize: 12,
};
export const linkStyle = { ...simpleLinkStyle, padding: "10px 20px" };

export const menuLinkStyle = {
  ...simpleLinkStyle,
  fontSize: 48,
};

export const greenLinkStyle = {
  ...linkStyle,
  backgroundColor: "#1DD1A1",
  color: "white",
  borderRadius: "30px",
};

export const actionButton = {
  ...linkStyle,
  textTransform: "none",
  backgroundColor: "#5F27CD",
  borderRadius: "25px",
};

export const menuActionButton = {
  ...actionButton,
  backgroundColor: "#48dbfb",
  color: "white",
};

export const loginRedirectLink = {
  ...actionButton,
  backgroundColor: "#1DD1A1",
  border: "2px solid rgb(52, 31, 151)",
};

export const subscribeButton = {
  ...actionButton,
  backgroundColor: "#341F97",
  borderRadius: "0 25px 25px 0",
  padding: "0 20px",
};

export const successButton = {
  ...subscribeButton,
  backgroundColor: "#1dd1a1",
  padding: "0 20px 0 10px",
};

export const heroBg = {
  backgroundImage: `url(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23ffffff' fill-opacity='0.07' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E"
  )`,
};

export const aboutBg = {
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%235f27cd' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
};

export const menuRegisterButton = {
  fontSize: 38,
  fontWeight: 700,
  color: "#48dbfb",
  textDecoration: "none",
};
