import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Stack,
  Toolbar,
  Container,
  Grid,
  useTheme,
} from "@mui/material";
import {
  greenLinkStyle,
  loginRedirectLink,
  simpleLinkStyle,
  smallLinkStyle,
} from "../helpers/styling";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Link,
  NavLink,
  useHref,
} from "react-router-dom";

export default function Footer(props) {
  var { sx, titleText, bodyText, jumpTo } = props;
  var theme = useTheme();
  var linkNavHeaderStyle = {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.secondary,
  };
  return (
    <Toolbar
      style={{
        position: "static",
        backgroundColor: theme.palette.darker.main,
        bottom: 0,
        color: "white",
      }}
    >
      <Container
        sx={{
          pt: 5,
          pb: 5,
        }}
      >
        <Grid container spacing={8}>
          <Grid item xs={12} md={2}>
            <Stack spacing={1}>
              <Typography style={linkNavHeaderStyle}>Learn More</Typography>
              <Typography sx={{ display: "none" }}>The Basics</Typography>
              <NavLink to="./roasters" style={simpleLinkStyle}>
                Roasters
              </NavLink>
              <NavLink to="./about" style={simpleLinkStyle}>
                About
              </NavLink>
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack spacing={0}>
              <Typography style={linkNavHeaderStyle}>Need Help?</Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Email us at
              </Typography>
              <Typography
                sx={{ color: theme.palette.primary.secondary, fontWeight: 300 }}
              >
                support@haste.coffee
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}></Grid>
        </Grid>
        <Grid container sx={{ mt: 12 }} spacing={2}>
          {/*}
          <Grid item xs={12} md={2}>
            <Link to="/privacy-policy" style={smallLinkStyle}>
              Terms of service
            </Link>
      </Grid> {*/}
          <Grid item xs={12} md={2}>
            <Link to="/privacy-policy" style={smallLinkStyle}>
              Privacy policy
            </Link>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Copyright © 2023 Haste Coffee LLC
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Toolbar>
  );
}
