import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Link,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme,
  Grid,
  TextField,
  Slide,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { heroBg } from "../helpers/styling";
import useMediaQuery from "@mui/material/useMediaQuery";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAOpoRBowpOtS1zHXtAJ7Q_hx8aDI1B-HU",
  authDomain: "haste-roastery-website.firebaseapp.com",
  projectId: "haste-roastery-website",
  storageBucket: "haste-roastery-website.appspot.com",
  messagingSenderId: "181666686612",
  appId: "1:181666686612:web:ca39c7cd3d6da5a24e7681",
  measurementId: "G-84K9953X89",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const categories = [
  {
    value: "personal",
    label: "Personal Use",
  },
  {
    value: "friends and family",
    label: "Friends and Family",
  },
  {
    value: "local community",
    label: "Local Community",
  },
  {
    value: "retail and cafes",
    label: "Retail and Cafes",
  },
  {
    value: "other",
    label: "Other",
  },
];

export default function RegisterDialog(props) {
  var theme = useTheme();
  var { onClose, open } = props;
  var [sent, setSent] = useState(false);
  var [loading, setLoading] = useState(false);
  var [success, setSuccess] = useState(false);

  var [first, setFirst] = useState("");
  var [last, setLast] = useState("");
  var [email, setEmail] = useState("");
  var [roasteryName, setRoasteryName] = useState("");
  var [category, setCategory] = useState("");
  var [otherText, setOtherText] = useState("");

  var [formValid, setFormValid] = useState(false);
  const matches = useMediaQuery("(min-width:1100px)");

  const resetFields = () => {
    setFirst("");
    setLast("");
    setEmail("");
    setRoasteryName("");
    setCategory("");
    setOtherText("");
    setFormValid(false);
  };

  const closeDialog = () => {
    // setSent(false);
    console.log(first, last, email, roasteryName, category, otherText);
    console.log(validateForm());
    resetFields();
    onClose();
  };

  const handleFirstChange = (e) => {
    setFirst(e.target.value);
    console.log(e);
  };
  const handleLastChange = (e) => {
    setLast(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleRoasteryNameChange = (e) => {
    setRoasteryName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleOtherText = (e) => {
    setOtherText(e.target.value);
  };

  function onFormSend() {
    console.log("formSend");
    if (formValid) {
      SendConnection();
      window.location.href = "https://hasteroastery.io/";
    }
  }

  function validateForm() {
    var currentEmails = email.split(",").filter((e) => e && e.trim());
    var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    for (var i = 0; i < currentEmails.length; i++) {
      if (!regex.test(currentEmails[i].replace(/\s/g, ""))) {
        console.log("Email failed validation");
        setFormValid(false);
        return;
      }
    }

    if (first && last && email && roasteryName && category) {
      if (category == "other" && otherText) {
        setFormValid(true);
        return;
      } else if (category != "other") {
        setFormValid(true);
        return;
      }
    }

    setFormValid(false);
    return;
  }

  function sendEmailNotification() {}

  async function SendConnection() {
    const subRef = collection(db, "ConnectRequests");

    setLoading(true);
    await addDoc(subRef, {
      first_name: first,
      last_name: last,
      email: email,
      roastery_name: roasteryName,
      category: category,
      category_text: otherText ? otherText : "",
      timestamp: serverTimestamp(),
    }).then(() => {
      setSuccess(true);
      setLoading(false);
      setSent(true);
      sendEmailNotification();
      console.log("success");
    });
  }

  useEffect(() => {
    validateForm();
  }, [first, last, email, roasteryName, category, otherText]);

  return (
    <Dialog
      fullWidth
      fullScreen={!matches}
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          border: "8px solid white",
        }}
      >
        {sent && (
          <React.Fragment>
            <DialogContent
              sx={{
                borderRadius: "5px",
                m: 3,
                backgroundColor: theme.palette.primary.main,
                textAlign: "center",
                p: 5,
              }}
              style={heroBg}
            >
              <Typography variant="h3" sx={{ display: "none", fontSize: 22 }}>
                Nice to meet you - We will be in touch!
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 1 }}>
              <Button
                sx={{ mr: 1, borderRadius: "30px" }}
                onClick={closeDialog}
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
        {!sent && (
          <React.Fragment>
            <DialogTitle
              sx={{
                backgroundColor: theme.palette.primary.main,

                p: 2,
                borderRadius: "5px",
                textAlign: "center",
              }}
              style={heroBg}
            >
              <Typography
                sx={{ color: "white", fontWeight: 600, fontSize: 22 }}
              >
                Let's Get Connected
              </Typography>
              <Typography
                sx={{ color: "white", fontWeight: 500, fontSize: 16 }}
              ></Typography>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ p: matches ? 3 : 1, pt: 3 }}>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography sx={{ color: theme.palette.primary.main }}>
                        The Basics
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={first}
                        onChange={handleFirstChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={last}
                        onChange={handleLastChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography sx={{ color: theme.palette.primary.main }}>
                        Your Roastery
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Roastery Name"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={roasteryName}
                        onChange={handleRoasteryNameChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: 12 }}>
                        Which most closely describes your consumers?
                      </Typography>
                      <TextField
                        label=""
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        select
                        value={category}
                        onChange={handleCategoryChange}
                      >
                        {categories.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {category == "other" && (
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          value={otherText}
                          onChange={handleOtherText}
                          label="Tell use a bit more about you"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          rows={3}
                        ></TextField>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ p: 1 }}>
              <Button
                sx={{ mr: 1, borderRadius: "30px" }}
                onClick={closeDialog}
              >
                Close
              </Button>
              <Button
                disabled={!formValid}
                variant="contained"
                sx={{ borderRadius: "30px" }}
                onClick={onFormSend}
              >
                Let's Go!
              </Button>
            </DialogActions>{" "}
          </React.Fragment>
        )}
      </Box>
    </Dialog>
  );
}
