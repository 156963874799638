import React from "react";
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Fade,
  Slide,
  Paper,
  Grid,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, useParams } from "react-router-dom";
import Sourcing from "./Articles/Sourcing";

import { styled } from "@mui/material/styles";

const Item = styled(NavLink)(({ theme, active }) => ({
  textDecoration: "none",
  color: theme.palette.primary.secondaryColor,
  fontWeight: active ? 600 : 300,
  fontSize: 16,
}));

export default function Blog() {
  var theme = useTheme();
  const matches = useMediaQuery("(min-width:1100px)");
  const { blogid } = useParams();
  var article = blogid;

  console.log(blogid);
  if (article === undefined) {
    article = "sourcing";
  }

  return (
    <React.Fragment>
      <Box sx={{ height: "70px" }}></Box>
      <Container sx={{ pt: 5, pb: 10 }} maxWidth="lg">
        <Grid container>
          <Grid item xs={2} sx={{ mt: 2 }}>
            <Stack direction="column" spacing={1}>
              <Typography>Articles</Typography>
              <Item to="/blog/sourcing" active={article == "sourcing"}>
                Sourcing
              </Item>
              <Item to="/blog/storage" active={article == "storage"}>
                Storage
              </Item>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <Sourcing />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
