import React from "react";
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Fade,
  Slide,
  useTheme,
} from "@mui/material";
import { ReactComponent as Border } from "../imgs/border.svg";
import { aboutBg } from "../helpers/styling";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function About() {
  var theme = useTheme();
  const matches = useMediaQuery("(min-width:1100px)");
  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "#341F97", height: "65vh" }}>
        {" "}
        <Container
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Fade in={true} timeout={{ enter: 1500 }}>
              <Box>
                <Slide direction="up" in={true} timeout={{ enter: 1000 }}>
                  <Stack sx={{}}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: matches ? 50 : 32,
                        fontWeight: 600,
                      }}
                    >
                      Haste was built for you
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: matches ? 30 : 22,
                        fontWeight: 300,
                      }}
                    >
                      To spread the joy of coffee
                    </Typography>
                  </Stack>
                </Slide>
              </Box>
            </Fade>
          </Box>
        </Container>
      </Box>
      <Border />
      <Box sx={{ pt: 0, pb: 20 }}>
        <Stack direction="column" spacing={15}>
          <Container sx={{}}>
            <Fade in={true} timeout={{ enter: 1500 }}>
              <Box>
                <Slide direction="up" in={true} timeout={{ enter: 1000 }}>
                  <Stack
                    direction="column"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    spacing={5}
                  >
                    <Box sx={{ textAlign: "center", width: "90%" }}>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: 500, fontSize: matches ? 32 : 20 }}
                      >
                        Our mission is to provide roasters of all sizes what
                        they need to produce great coffee and engage directly
                        with their communities.
                      </Typography>
                    </Box>
                    <Box sx={{ width: "80%" }}>
                      <img
                        src="./cafe.png"
                        loading="lazy"
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Stack>
                </Slide>
              </Box>
            </Fade>
          </Container>
          <Box>
            <Stack direction="column" spacing={0}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  p: 5,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: matches ? "65%" : "90%" }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "white", mt: 2, fontSize: matches ? 24 : 20 }}
                  >
                    Data is key to producing high quality coffee. Having tools
                    at your finger tips to track roasts across your roastery and
                    to dive into the details to replicate and improve upon your
                    product.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  pt: 10,
                  pb: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
                style={aboutBg}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    pt: 3,
                    pb: 3,
                    pl: 7,
                    pr: 7,
                    m: 3,
                    borderRadius: "25px",
                    border: "5px solid " + theme.palette.primary.main,
                    boxShadow: "0 0px 0px 12px rgba(255,255,255,0.7)",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.primary.main,
                      pl: 1,
                      pr: 1,
                      fontWeight: 600,
                      fontSize: matches ? 22 : 16,
                    }}
                  >
                    Then engage directly with your customers
                  </Typography>
                </Box>
              </Box>{" "}
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  p: 5,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: matches ? "65%" : "90%" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: matches ? 26 : 25,
                      fontWeight: 700,
                      color: "white",
                    }}
                  >
                    The Good Stuff
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "white", mt: 2, fontSize: matches ? 24 : 20 }}
                  >
                    At the end of the day, your customers and how they
                    experience your craft is what matters. Put data into their
                    hands to help them best experience your coffee and enable
                    you to connect with them directly.
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Container>
              <Stack
                direction="column"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                spacing={5}
              >
                <Box sx={{ height: matches ? "500px" : "300px" }}>
                  <img
                    src="./person.png"
                    loading="lazy"
                    style={{
                      borderRadius: "20px",
                      height: "100%",
                    }}
                  />
                </Box>
                <Box sx={{ textAlign: "center", width: "90%" }}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: 500, fontSize: matches ? 32 : 20 }}
                  >
                    Advancing transparency is crucial in the coffee industry,
                    and leveraging management software to track roast data and
                    your green coffee is key. Sharing this data with your
                    community improves their coffee experience, builds trust,
                    and fosters sustainability. By connecting with your
                    community and providing roast data, you not only improve
                    your product but also make a positive impact in the coffee
                    industry, benefitting both coffee producers and consumers
                    alike.
                  </Typography>
                </Box>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
