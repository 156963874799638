import React, { useState, useRef } from "react";
import { Box, Typography, Link, Stack, useTheme } from "@mui/material";
import { linkStyle, loginRedirectLink } from "../helpers/styling";
import RegisterDialog from "./RegisterDialog";
export default function Register(props) {
  var theme = useTheme();
  var { sx, registerClick } = props;

  const [registerOpen, setRegisterOpen] = useState(false);
  function toggleRegister() {
    window.location.href = "https://hasteroastery.io/";
    return;
    setRegisterOpen(!registerOpen);
  }

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "375px",
        textAlign: "center",
      }}
    >
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{ color: theme.palette.primary.main, fontSize: 38 }}
        >
          Let's get roasting
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: theme.palette.primary.main, fontSize: 22 }}
        >
          Creating an account is free
        </Typography>
        <Box sx={{ width: "100%", pt: 5, pr: 1 }}>
          <Link
            style={loginRedirectLink}
            sx={{ fontSize: "20px", cursor: "pointer" }}
            onClick={toggleRegister}
          >
            Sign Up
          </Link>
        </Box>
      </Stack>
      <RegisterDialog open={registerOpen} onClose={toggleRegister} />
    </Box>
  );
}
