import React from "react";
import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Fade,
  Slide,
  useTheme,
} from "@mui/material";
import { ReactComponent as Border } from "../imgs/border.svg";
import { aboutBg } from "../helpers/styling";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PrivacyPolicy() {
  var theme = useTheme();
  const matches = useMediaQuery("(min-width:1100px)");
  return (
    <React.Fragment>
      <Box sx={{ height: "70px" }}></Box>
      <Container sx={{ pt: 5, pb: 10 }} maxWidth="lg">
        <Stack direction="column" spacing={3}>
          <Box>
            <Typography variant="h1">
              Haste Coffee LLC Privacy Policy
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" sx={{ fontSize: 16 }}>
              Last Updated: March 8, 2023
            </Typography>
          </Box>
          <Box>
            <Stack direction="column" spacing={3}>
              <Typography>
                This Privacy Policy describes how Haste Coffee LLC
                (collectively, "Haste", "we") collects and uses information
                related to your Haste account and other related services. This
                policy applies to services we provide through the public-facing
                website to engage and reach out to interested individuals, in
                addition to any software we provide to you through our services.{" "}
                <br />
                Any new information related to our privacy policy and how we
                collect and use your data will be updated here. The date at the
                top of this page indicates when the policy was last updated. We
                encourage you to read through this policy to understand how your
                data is used, and to reach out to support@haste.coffee if you
                have any questions.
              </Typography>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  Information Collection
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Information You Give
                  </Typography>
                  <Typography>
                    Information that you provide to us is collected and stored.
                    For example, information that you provide when signing up
                    for company updates, or requesting an account related to
                    Haste services. Additionally, we provide you services within
                    Haste products to save green coffee and roasting profiles
                    and other coffee related details. The types of information
                    we collect include green and roast names, descriptions,
                    origins, taste notes, roast profiles, best by dates, and
                    brew related tips.
                  </Typography>
                  <Typography sx={{ fontWeight: 600, mt: 1 }}>
                    Information We Derive
                  </Typography>
                  <Typography>
                    With our services, we derive a variety of information with
                    our services. Information includes roast dates, projected
                    best by dates, key roast profiles events, and customer
                    engagement detail such as number of visits and likes related
                    to provided micro-websites.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  Use of Information
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography>
                    We use this information to provide you our services
                    including the feature and functionality of our software,
                    debug and fix errors, update account information, and
                    provide you information about current and future services.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  Controls
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Account Information
                  </Typography>
                  <Typography>
                    Information associated to your account is in your control.
                    For information that you provide you can either update it
                    directly or request to have it deleted. For derived
                    information associated to your account, contact us for
                    further resolution.{" "}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  Contact Us
                </Typography>
                <Box>
                  <Typography>
                    Reach out to support@haste.coffee if you have any questions
                    about this Privacy Policy and we'd be glad to assist.
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
